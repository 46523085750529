<template>
  <v-row>
    <slot></slot>
    <v-col :cols="12">
      <v-btn class="btn-plus-item" depressed @click="createItem()">
        <v-icon>mdi-plus</v-icon>
        Novo Item
      </v-btn>
    </v-col>
    <v-col v-if="!items.length" :cols="12">
      <span>Nenhum item cadastrado nesta nota.</span>
    </v-col>
    <expansion-panel
      v-else
      :contentProps="{ eager: true }"
      :headerProps="{ color: '#81b4ea' }"
      :items="items"
      :panelProps="panelProps"
    >
      <template v-slot:header="{ item, index }">
        <span>
          {{ item.descricao }}
        </span>
        <span v-if="errors.has(index)" class="has-erros">
          {{ getErrors(index) }}
        </span>
        <v-btn
          class="icon-btn-title"
          depressed
          icon
          title="Duplicar item e suas depreciações"
          @click.stop="createItem(item)"
        >
          <v-icon>mdi-content-duplicate</v-icon>
        </v-btn>
        <template v-if="canDelete">
          <v-btn
            v-if="item.delete"
            class="icon-btn-title"
            depressed
            icon
            title="Desfazer exclusão"
            @click.stop="deleteItem(item, true)"
          >
            <v-icon>mdi-undo-variant</v-icon>
          </v-btn>
          <v-btn
            v-else
            class="icon-btn-title"
            depressed
            icon
            title="Excluir item"
            @click.stop="deleteItem(item)"
          >
            <v-icon>mdi-trash-can</v-icon>
          </v-btn>
        </template>
      </template>
      <template v-slot:content="{ item, index }">
        <v-col :cols="12">
          <items-form
            :canDelete="canDelete"
            :projectsByYear="projectsByYear"
            :value="item"
            @input="inputHandler(index, $event)"
            @loadProjectsFromYear="$emit('loadProjectsFromYear', $event)"
            @update:valid="setValids(index, $event)"
          ></items-form>
        </v-col>
      </template>
    </expansion-panel>
  </v-row>
</template>

<script>
import { cloneDeep } from "lodash";

export default {
  components: {
    "expansion-panel": () => import("@/components/expansion-panel.vue"),
    "items-form": () => import("./items.form.vue"),
  },
  computed: {
    panelProps: function () {
      return (item) => ({
        disabled: item.delete,
      });
    },
  },
  created: function () {
    this.$emit('update:valid', this.generateUpdateValid());
  },
  data: function () {
    return {
      errors: new Map(),
      valids: new Map(),
    };
  },
  methods: {
    createItem: function (itemRef = undefined) {
      let item = {
        descricao: "Novo item",
        valor: 0,
        valor_deprec: "0",
        depreciacoes: [],
      };

      if (itemRef) {
        item = cloneDeep(itemRef);
        item.descricao = "Duplicado — " + item.descricao;
        delete item.id;

        if (item.depreciacoes?.length > 0) {
          item.depreciacoes.forEach((depre) => {
            delete depre.id;
          });
        }
      }

      this.items.push(item);
      this.$emit('input', this.items);
    },
    deleteItem: function (itemRef, restore = false) {
      const index = this.items.indexOf(itemRef);
      itemRef.delete = !restore;
      this.items.splice(index, 1, itemRef);
      this.$emit('input', this.items); // TODO será que só assim vai subir a alteração para cima??
    },
    generateUpdateValid: function () {
      const self = this;
      return () => {
        const valids = Array.from(self.valids.entries());
        const numErrors = valids.reduce((acc, [index, fn]) => {
          if (self.items[index].delete || typeof fn !== 'function') {
            return acc;
          }

          const num = fn()?.length || 0;
          return acc + num;
        }, 0);
        return numErrors === 0;
      };
    },
    getErrors: function (index) {
      const errors = this.errors.get(index);
      const lastError = errors.pop();

      if (errors.length === 0) {
        return `Há um erro no campo ${lastError}`;
      }

      return `Há erros nos campos ${errors.join(', ')} e ${lastError}`;
    },
    inputHandler: function (index, depre) {
      const items = cloneDeep(this.items);
      items.splice(index, 1, depre);
      this.$emit('input', items);
    },
    setValids: function (index, valid) {
      this.valids.set(index, valid);
    },
  },
  name: "items-panels",
  props: {
    canDelete: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => ([]),
    },
    projectsByYear: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>

<style lang="scss" scoped>
span.has-erros {
  color: red;
}

.icon-btn-title {
  &.v-btn.v-btn--depressed.v-btn--flat.v-btn--icon.v-btn--round.theme--light.v-size--default {
    flex: 0 1 36px;
    pointer-events: all;

    ::before {
      color: #fff;
    }
  } 
}

.btn-plus-item.v-btn.v-btn--depressed.theme--light.v-size--default {
  background-color: #81b4ea;
  box-shadow: 0px 3px 1px -2px #81b4ea, 0px 2px 2px 0px #81b4ea,
    0px 1px 5px 0px #81b4ea;
  color: #fff;
  float: right;
}

::v-deep {
  .v-expansion-panel-header {
    color: #fff;
    justify-content: space-between;
  }
  
  .theme--light.v-expansion-panels
    .v-expansion-panel-header
    .v-expansion-panel-header__icon
    .v-icon {
    color: #fff;
  }
}
</style>